import React, { useState, useEffect, useContext, createContext } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import LitImage from './images/LitImage';
import PostcodeSearch from './PostcodeSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthenticatedTemplate } from '@azure/msal-react';
import userIcon from '../images/icon-images/user.svg';
import {
  faChevronDown,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../hooks/useScreenSize';
import AddressContext from '../context/AddressContext';
const Nav = ({ hideNav, hidePostcode, showAddress }) => {
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const { currentAddress, setCurrentAddress } = useContext(AddressContext);
  const data = query();
  const campaignSlug = data.allDatoCmsCampaignLandingPageModel.nodes[0].slug;
  const allNavigationData =
    data.allDatoCmsNavigation.nodes[0].navigationContent;
  const orderBasketData =
    data.allDatoCmsOrderJourneyModel.nodes[0].stepConfirmPackage;
  const screenSize = useScreenSize();

  const toggle = (i) => {
    if (mobileNavIsOpen === i) {
      setMobileNavIsOpen(!mobileNavIsOpen);
    } else if (!mobileNavIsOpen == i) {
      setMobileNavIsOpen(i);
    } else {
      setMobileNavIsOpen(i);
    }
  };

  const navClosed = () => {
    setNavIsOpen(!navIsOpen);
  };

  const mapNavContent = (links, navColumn) => {
    return links
      .filter(({ column, showInNav }) => column === navColumn && showInNav)
      .map(({ text, path, isExternal }, index) => {
        return !isExternal ? (
          <li key={index}>
            <Link
              to={path === '/campaign/' ? `${path}${campaignSlug}` : `${path}`}
              onClick={navClosed}
            >
              {text}
            </Link>
          </li>
        ) : (
          <li key={index}>
            <a href={path} target="_blank">
              {text}
            </a>
          </li>
        );
      });
  };

  return (
    <header className="bootstrap">
      <nav className="navigation">
        <div className="navigation-content-container">
          <div className="nav-mobile-container">
            <div className="nav-logo-container">
              <Link
                to="/"
                onClick={() => {
                  setNavIsOpen(false);
                }}
                title="Lit Fibre"
                aria-label="Lit Fibre"
              >
                <div className="nav-logo" />
              </Link>
            </div>
            {showAddress &&
              currentAddress?.singleLineAddress &&
              screenSize.width <= 800 && (
                <div className="basket-address-container">
                  <div className="basket-address-content">
                    <h5>Your Address</h5>
                    <h6>
                      {/* {orderState.leadName && orderState.leadName + <br />} */}
                      {currentAddress?.singleLineAddress}
                    </h6>
                  </div>
                  <div className="basket-address-image">
                    <LitImage
                      image={orderBasketData.addressImage}
                      alt="house icon with trees"
                    />
                  </div>
                </div>
              )}
            <AuthenticatedTemplate>
              <Link
                to="/internal/sales/"
                className="userIcon"
                aria-label="User Icon"
              >
                <img src={userIcon} alt="person outline icon" />
              </Link>
            </AuthenticatedTemplate>
            {!hideNav && (
              <div
                className={`nav-burger-container ${
                  navIsOpen && 'burger-animate'
                }`}
              >
                <button
                  onClick={navClosed}
                  title="Navigation burger button"
                  aria-label="Navigation burger button"
                >
                  <span className="nav-burger"></span>
                </button>
              </div>
            )}
          </div>
          {/* PurgeCSS include: nav-open, nav-closed, rotate, reset, nav-submenu-open, nav-submenu-closed  */}
          {!hideNav && (
            <div
              className={
                screenSize.width < 800 &&
                (navIsOpen ? 'nav-open' : 'nav-closed')
              }
            >
              <div className={`nav-content`}>
                {allNavigationData.map(
                  (
                    {
                      navTopLevelLinkText,
                      navTopLevelPath,
                      noSubmenu,
                      navSubmenuLinks,
                      navSubmenuIntro,
                      columnCount,
                    },
                    index,
                  ) => {
                    return (
                      <ul className="nav-container" key={index}>
                        {!noSubmenu ? (
                          <li className="nav-top-level-links">
                            <a
                              onClick={() => {
                                toggle(index);
                              }}
                            >
                              {navTopLevelLinkText}
                            </a>
                            <span
                              className={`${
                                mobileNavIsOpen === index ? 'rotate' : 'reset'
                              }`}
                            >
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                            <div
                              className={`nav-submenu-container ${
                                mobileNavIsOpen === index &&
                                screenSize.width <= 800
                                  ? 'nav-submenu-open'
                                  : 'nav-submenu-closed'
                              }`}
                            >
                              <ul className="nav-submenu">
                                <div className="nav-submenu-intro">
                                  <LitImage
                                    image={navSubmenuIntro?.navPromoImage}
                                  />
                                  <h4>{navSubmenuIntro?.navPromoHeader}</h4>
                                  <p>{navSubmenuIntro?.navPromoBody}</p>
                                </div>
                                {[...Array(columnCount)].map((_, index) => {
                                  return (
                                    <div className="nav-submenu-links">
                                      {mapNavContent(
                                        navSubmenuLinks,
                                        index + 1,
                                      )}
                                    </div>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        ) : (
                          <li className="nav-top-level-links">
                            <Link to={navTopLevelPath} onClick={navClosed}>
                              {navTopLevelLinkText}
                            </Link>
                          </li>
                        )}
                      </ul>
                    );
                  },
                )}
                {!hidePostcode && (
                  <div className="nav-postcode-search">
                    <PostcodeSearch
                      textButton={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                      placeholder="Check availability"
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                      inputDisplayValue={inputDisplayValue}
                      setInputDisplayValue={setInputDisplayValue}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {showAddress &&
            currentAddress?.singleLineAddress &&
            screenSize.width >= 800 && (
              <div className="basket-address-container">
                <div className="basket-address-content">
                  <h5>Your Address</h5>
                  <h6>
                    {/* {orderState.leadName && orderState.leadName + <br />} */}
                    {currentAddress?.singleLineAddress}
                  </h6>
                </div>
                <div className="basket-address-image">
                  <LitImage
                    image={orderBasketData.addressImage}
                    alt="house icon with trees"
                  />
                </div>
              </div>
            )}
          <div className="user-icon-desktop">
            <AuthenticatedTemplate>
              <Link
                to="/internal/sales/"
                className="userIcon"
                aria-label="User Icon"
              >
                <img src={userIcon} alt="person outline icon" />
              </Link>
            </AuthenticatedTemplate>
          </div>
        </div>
      </nav>
    </header>
  );
};
Nav.propTypes = {
  siteTitle: PropTypes.string,
};

Nav.defaultProps = {
  siteTitle: '',
};
export default Nav;
const query = () =>
  useStaticQuery(graphql`
    query {
      allDatoCmsNavigation {
        nodes {
          navigationContent {
            columnCount
            navTopLevelLinkText
            navTopLevelPath
            noSubmenu
            navSubmenuIntro {
              navPromoBody
              navPromoHeader
              navPromoImage {
                gatsbyImageData
              }
            }
            navSubmenuLinks {
              isExternal
              showInNav
              column
              path
              text
            }
          }
        }
      }
      allDatoCmsOrderJourneyModel {
        nodes {
          stepConfirmPackage {
            addressImage {
              gatsbyImageData
            }
          }
        }
      }
      allDatoCmsCampaignLandingPageModel {
        nodes {
          id
          pageTitleInternalOnly
          slug
        }
      }
    }
  `);
