import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Footer from './Footer';
import LiveChat from './LiveChat';
import DeviceProvider from './contexts/device/DeviceProvider';
//import PromotionBanner from './promotions/PromotionBanner';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import Recaptcha from './Recaptcha';
import Nav from './Nav';
import {
  getAuthConfigForRoute,
  salesJourneyAuth,
} from '../data/Authentication';
import MsalAuthWrapper from './authentication/MsalAuthWrapper';
import { renderMetaTags } from 'react-datocms/seo';
import FacebookConversionsEvent from './tracking/FacebookConversionsApi';
import FacebookPixelEvents from '../services/FacebookPixelEvents';
import AddressContext from '../context/AddressContext';
import OneTouchSwitchContext from '../context/OneTouchSwitchContext';
// Lazy load
const PromotionBanner = loadable(() => import('./promotions/PromotionBanner'), {
  fallback: <div></div>,
});
const Layout = ({ children, containerType, displayLiveChat, location }) => {
  const [gaTrack, setGaTrack] = useState();
  const [displayBanners, setDisplayBanners] = useState(
    !Cookies.get('gatsby-gdpr-google-analytics') ? false : true,
  );
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  const overrideChat = process.env.GATSBY_GLOBAL_LIVECHAT;
  const cookieFirstUrl = process.env.GATSBY_COOKIE_FIRST_URL;
  const [facebookEventId, setFacebookEventId] = useState('');
  let fbEventId = '';
  let aid;

  if (overrideChat === 'true' || overrideChat === undefined) {
    displayLiveChat = true;
  }

  const forceTrailingSlash = (path) => {
    return path.slice(-1) === '/' ? path : path + '/';
  };

  const [pathname, setPathName] = useState(
    forceTrailingSlash(location.pathname),
  );
  if (location.pathname.includes('/internal/sales')) {
    displayLiveChat = false;
  }

  const hideNavPaths = [
    /\/get-lit\/order/,
    /\/secure-order/,
    /\/internal\/sales\/order/,
    /\/internal\/sales\/register-interest/,
  ];
  const hideNav = hideNavPaths.some((hideNavPath) =>
    hideNavPath.test(pathname),
  );
  const hidePostCodePaths = [/\/internal\/sales/, /\/internal\/sales\/order/];
  const hidePostcode = hidePostCodePaths.some((hideNavPaths) =>
    hideNavPaths.test(pathname),
  );
  const authConfig = salesJourneyAuth;
  const showAddressPaths = [/\/secure-order\/contact-form-page/];
  const showAddress = showAddressPaths.some((showNavPath) =>
    showNavPath.test(pathname),
  );

  useEffect(() => {
    setPathName(forceTrailingSlash(location.pathname));
    if (typeof self !== 'undefined') {
      fbEventId = self.crypto.randomUUID();
      setFacebookEventId(fbEventId);
    }
    FacebookPixelEvents.init();
    FacebookPixelEvents.pageView(fbEventId);
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    aid = urlParams.get('aid');
    if (aid) {
      Cookies.set('aid', aid);
    }
  }, [location]);

  // Preconnect to script URLs
  // Instantiates the Device Helper on the Page
  const pagePathname = location.pathname.replace(/[/-]/g, ' ');

  const getDefaultAddress = () => {
    const cookie = Cookies.get('litfibre-address');
    if (cookie) {
      return JSON.parse(atob(cookie));
    }
    return {};
  };
  const [currentAddress, setCurrentAddress] = useState(getDefaultAddress());

  return (
    <>
      <AddressContext.Provider value={{ currentAddress, setCurrentAddress }}>
        <Helmet>
          <link rel="preconnect" href="https://connect.facebook.net" />
          <link rel="preconnect" href="https://bat.bing.com" />
          <link rel="preconnect" href="https://www.google.com" />
          <link rel="preconnect" href="https://www.gstatic.com" />
          <meta
            property="og:image"
            content="https://www.datocms-assets.com/101950/1715855762-lit-fibre-og-image.png?auto=format&w=548.0999612808228"
          />
          <meta property="og:url" content={location.pathname} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=2"
          />
          <meta name="yandex-verification" content="4cf0aecb0deb90b1" />
          <meta
            name="facebook-domain-verification"
            content="o30v385nzxl9qbqhwfo0eodnmj9nn3"
          />
          <script src="https://api.aklamio.com/tracking.min.js"></script>
          {cookieFirstUrl && <script src={cookieFirstUrl}></script>}
        </Helmet>
        <DeviceProvider>
          <LiveChat enabled={displayLiveChat} />
          <MsalAuthWrapper authConfig={authConfig} pathname={pathname}>
            <Nav
              siteTitle={data.site.siteMetadata.title}
              hideNav={hideNav}
              hidePostcode={hidePostcode}
              showAddress={showAddress}
            />
            {displayBanners && <>{/* <CovidBanner /> */}</>}
            <main
              className={
                containerType === 'flex'
                  ? `container-flex ${pagePathname}`
                  : `container-main ${pagePathname}`
              }
            >
              {displayBanners && (
                <PromotionBanner
                  path={pathname}
                  allowedPaths={[
                    '/fibre-broadband/all-products/',
                    '/fibre-broadband/lit100/',
                    '/fibre-broadband/lit500/',
                    '/fibre-broadband/lit1000/',
                    '/fibre-broadband/your-kind-of-fast/',
                  ]}
                />
              )}
              {children}
            </main>
            {/* </div> */}
            <Footer siteTitle={data.site.siteMetadata.title} />
          </MsalAuthWrapper>
          {!location.href.includes('internal') && (
            <FacebookConversionsEvent
              eventName="PageView"
              eventId={facebookEventId}
            />
          )}
          <Recaptcha />
        </DeviceProvider>
      </AddressContext.Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  containerType: PropTypes.string,
  displayLiveChat: PropTypes.bool,
};

Layout.defaultProps = {
  containerType: 'main',
  displayLiveChat: false,
};

export default Layout;
